import { createSlice } from "@reduxjs/toolkit"
import {
  createService,
  createServiceCompany,
  deleteService,
  deleteServiceCompany,
  getAllServiceCompany,
  getAllServices,
  getServiceCompanyDetails,
  getServiceCompanyList,
  getServiceDetails,
  getServicesList,
  syncServiceWithERP,
  updateService,
  updateServiceCompany,
  updateServiceCompanyStatus,
  updateServiceStatus,
} from "store/actions/servicesAction"

const initialState = {
  loading: true,
  isLoading: false,
  perPage: 10,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  services: [],
  servicesList: [],
  serviceDetails: {},
  serviceCompany: [],
  serviceCompanyList: [],
  serviceCompanyDetails: {},
  gettingDetails: false,
}
const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getAllServices.pending, state => {
        state.loading = true
      })
      .addCase(getAllServices.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.services = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllServices.rejected, (state, action) => {
        state.loading = false
      })

    // GET LIST
    builder
      .addCase(getServicesList.pending, state => {})
      .addCase(getServicesList.fulfilled, (state, action) => {
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.servicesList = data
        }
      })
      .addCase(getServicesList.rejected, (state, action) => {})

    // GET DETAILS
    builder
      .addCase(getServiceDetails.pending, state => {
        state.loading = true
      })
      .addCase(getServiceDetails.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.serviceDetails = data
        }
      })
      .addCase(getServiceDetails.rejected, (state, action) => {
        state.loading = false
      })

    // UPDATE STATUS
    builder
      .addCase(updateServiceStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(updateServiceStatus.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id, status } = action.meta.arg
          state.services = state.services.map(item =>
            item._id === id ? { ...item, status: Number(status) } : item
          )
        }
      })
      .addCase(updateServiceStatus.rejected, (state, action) => {
        state.isLoading = false
      })

    // DELETE
    builder
      .addCase(deleteService.pending, state => {
        state.isLoading = true
      })
      .addCase(deleteService.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.services = state.services.filter(item => item._id !== id)
          if (state.totalCount !== null) {
            state.totalCount -= 1
          }
        }
      })
      .addCase(deleteService.rejected, (state, action) => {
        state.isLoading = false
      })

    // CREATE
    builder
      .addCase(createService.pending, state => {
        state.isLoading = true
      })
      .addCase(createService.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(createService.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPDATE
    builder
      .addCase(updateService.pending, state => {
        state.isLoading = true
      })
      .addCase(updateService.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(updateService.rejected, (state, action) => {
        state.isLoading = false
      })

    //
    builder
      .addCase(syncServiceWithERP.pending, state => {
        state.isLoading = true
      })
      .addCase(syncServiceWithERP.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(syncServiceWithERP.rejected, (state, action) => {
        state.isLoading = false
      })

    // ****************  SERVICE COMPANY START ******************
    builder
      .addCase(getAllServiceCompany.pending, state => {
        state.loading = true
      })
      .addCase(getAllServiceCompany.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.serviceCompany = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllServiceCompany.rejected, (state, action) => {
        state.loading = false
      })

    // GET LIST
    builder
      .addCase(getServiceCompanyList.pending, state => {})
      .addCase(getServiceCompanyList.fulfilled, (state, action) => {
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.serviceCompanyList = data
        }
      })
      .addCase(getServiceCompanyList.rejected, (state, action) => {})

    // GET DETAILS
    builder
      .addCase(getServiceCompanyDetails.pending, state => {
        state.gettingDetails = true
      })
      .addCase(getServiceCompanyDetails.fulfilled, (state, action) => {
        state.gettingDetails = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.serviceCompanyDetails = data
        }
      })
      .addCase(getServiceCompanyDetails.rejected, (state, action) => {
        state.gettingDetails = false
      })

    // UPDATE STATUS
    builder
      .addCase(updateServiceCompanyStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(updateServiceCompanyStatus.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id, status } = action.meta.arg
          state.serviceCompany = state.serviceCompany.map(item =>
            item._id === id ? { ...item, status: Number(status) } : item
          )
        }
      })
      .addCase(updateServiceCompanyStatus.rejected, (state, action) => {
        state.isLoading = false
      })

    // DELETE
    builder
      .addCase(deleteServiceCompany.pending, state => {
        state.isLoading = true
      })
      .addCase(deleteServiceCompany.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.serviceCompany = state.serviceCompany.filter(
            item => item._id !== id
          )
          if (state.totalCount !== null) {
            state.totalCount -= 1
          }
        }
      })
      .addCase(deleteServiceCompany.rejected, (state, action) => {
        state.isLoading = false
      })

    // CREATE
    builder
      .addCase(createServiceCompany.pending, state => {
        state.isLoading = true
      })
      .addCase(createServiceCompany.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success, data } = action.payload
        if (statusCode === 200 && success) {
          state.serviceCompany.push(data)
        }
      })
      .addCase(createServiceCompany.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPDATE
    builder
      .addCase(updateServiceCompany.pending, state => {
        state.isLoading = true
      })
      .addCase(updateServiceCompany.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success, data } = action.payload

        if (statusCode === 200 && success) {
          state.serviceCompany = state?.serviceCompany?.map(item =>
            item._id === data._id ? data : item
          )
        }
      })
      .addCase(updateServiceCompany.rejected, (state, action) => {
        state.isLoading = false
      })

    // ****************  SERVICE COMPANY END ******************
  },
})

export default servicesSlice.reducer
