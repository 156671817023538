import React, { useEffect, useRef, useCallback } from "react"
import { useLocation, useParams } from "react-router-dom"
import PropTypes from "prop-types"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { showActiveLink } from "store/reducers/sharedReducer"
import { hasAccess } from "components/Common/Utils"

// ICONS
import DashboardIcon from "assets/images/icons/sidebar/dashboard.svg"
import DashboardIconActive from "assets/images/icons/sidebar/dashboardActive.svg"
import ProfileIcon from "assets/images/icons/sidebar/profile.svg"
import ProfileIconActive from "assets/images/icons/sidebar/profileActive.svg"
import BannerIcon from "assets/images/icons/sidebar/banner.svg"
import BannerActiveIcon from "assets/images/icons/sidebar/bannerActive.svg"
import AppLookFeel from "assets/images/icons/sidebar/appLookFeel.svg"
import AppLookFeelActive from "assets/images/icons/sidebar/appLookFeelActive.svg"
import NotificationIcon from "assets/images/icons/sidebar/notification.svg"
import NotificationActiveIcon from "assets/images/icons/sidebar/notificationActive.svg"
import NewsIcon from "assets/images/icons/sidebar/news.svg"
import NewsActiveIcon from "assets/images/icons/sidebar/newsActive.svg"
import PartnerIcon from "assets/images/icons/sidebar/partner.svg"
import PartnerActiveIcon from "assets/images/icons/sidebar/partnerActive.svg"
import DownPaymentIcon from "assets/images/icons/sidebar/downPayment.svg"
import DownPaymentActiveIcon from "assets/images/icons/sidebar/downPaymentActive.svg"
import FullPaymentIcon from "assets/images/icons/sidebar/fullPayment.svg"
import FullPaymentActiveIcon from "assets/images/icons/sidebar/fullPaymentActive.svg"
import PaymentContractIcon from "assets/images/icons/sidebar/paymentContract.svg"
import PaymentContractActiveIcon from "assets/images/icons/sidebar/paymentContractActive.svg"
import TaxIcon from "assets/images/icons/sidebar/tax.svg"
import TaxActiveIcon from "assets/images/icons/sidebar/taxActive.svg"
import PaymentHistoryIcon from "assets/images/icons/sidebar/paymentHistory.svg"
import PaymentHistoryActiveIcon from "assets/images/icons/sidebar/paymentHistoryActive.svg"
import ComplaintsIcon from "assets/images/icons/sidebar/complaints.svg"
import ComplaintsActiveIcon from "assets/images/icons/sidebar/complaintsActive.svg"
import ReportsIcon from "assets/images/icons/sidebar/reports.svg"
import ReportsActiveIcon from "assets/images/icons/sidebar/reportsActive.svg"
import ProjectsIcon from "assets/images/icons/sidebar/projects.svg"
import ProjectsActiveIcon from "assets/images/icons/sidebar/projectActive.svg"
import UnitIcon from "assets/images/icons/sidebar/units.svg"
import UnitActiveIcon from "assets/images/icons/sidebar/unitsActive.svg"
import DeliveryReqIcon from "assets/images/icons/sidebar/deliveryRequest.svg"
import DeliveryReqActiveIcon from "assets/images/icons/sidebar/deliveryRequestActive.svg"
import DeedReqIcon from "assets/images/icons/sidebar/deedRequest.svg"
import DeedReqActiveIcon from "assets/images/icons/sidebar/deedRequestActive.svg"
import waterBillIcon from "assets/images/icons/sidebar/bill.svg"
import waterBillActiveIcon from "assets/images/icons/sidebar/billActive.svg"
import historyIcon from "assets/images/icons/sidebar/history.svg"
import historyActiveIcon from "assets/images/icons/sidebar/historyActive.svg"
import customerSupportIcon from "assets/images/icons/sidebar/customerSupport.svg"
import customerSupportActiveIcon from "assets/images/icons/sidebar/customerSupportActive.svg"
import RegisteredIcon from "assets/images/icons/sidebar/registeredInterest.svg"
import RegisteredActiveIcon from "assets/images/icons/sidebar/registeredInterestActive.svg"
import QRIcon from "assets/images/icons/sidebar/qr.svg"
import QRActiveIcon from "assets/images/icons/sidebar/qrActive.svg"
import customersIcon from "assets/images/icons/sidebar/customers.svg"
import customersActiveIcon from "assets/images/icons/sidebar/customersActive.svg"
import MaintenanceUserIcon from "assets/images/icons/sidebar/mUser.svg"
import MaintenanceUserActiveIcon from "assets/images/icons/sidebar/mUserActive.svg"
import UserIcon from "assets/images/icons/sidebar/user.svg"
import UserActiveIcon from "assets/images/icons/sidebar/userActive.svg"
import AttributeIcon from "assets/images/icons/sidebar/attribute.svg"
import AttributeActiveIcon from "assets/images/icons/sidebar/attributeActive.svg"
import ServiceIcon from "assets/images/icons/sidebar/services.svg"
import ServiceActiveIcon from "assets/images/icons/sidebar/servicesActive.svg"
import ServiceItemIcon from "assets/images/icons/sidebar/serviceItem.svg"
import ServiceItemActiveIcon from "assets/images/icons/sidebar/serviceItemActive.svg"
import RoomIcon from "assets/images/icons/sidebar/rooms.svg"
import RoomActiveIcon from "assets/images/icons/sidebar/roomsActive.svg"
import CityIcon from "assets/images/icons/sidebar/city.svg"
import CityActiveIcon from "assets/images/icons/sidebar/cityActive.svg"
import NotificationControlIcon from "assets/images/icons/sidebar/notificationControl.svg"
import NotificationControlActiveIcon from "assets/images/icons/sidebar/notificationControlActive.svg"
import RETTIcon from "assets/images/icons/sidebar/rett.svg"
import RETTActiveIcon from "assets/images/icons/sidebar/rettActive.svg"
import CustomerSupportSubjectIcon from "assets/images/icons/sidebar/customerSupportSubject.svg"
import CustomerSupportSubjectActiveIcon from "assets/images/icons/sidebar/customerSupportSubjectActive.svg"
import TermsIcon from "assets/images/icons/sidebar/terms.svg"
import TermsActiveIcon from "assets/images/icons/sidebar/termsActive.svg"
import PrivacyIcon from "assets/images/icons/sidebar/privacy.svg"
import PrivacyActiveIcon from "assets/images/icons/sidebar/privacyActive.svg"
import PaymentReceiptIcon from "assets/images/icons/sidebar/paymentReceipt.svg"
import PaymentReceiptActiveIcon from "assets/images/icons/sidebar/paymentReceiptActive.svg"
import RatingIcon from "assets/images/icons/sidebar/rating.svg"
import RatingActiveIcon from "assets/images/icons/sidebar/ratingActive.svg"
import GlobalSettingsIcon from "assets/images/icons/sidebar/globalSettings.svg"
import GlobalSettingsActiveIcon from "assets/images/icons/sidebar/globalSettingsActive.svg"
import ServiceCompany from "assets/images/icons/sidebar/serviceCompany.svg"
import ServiceCompanyActive from "assets/images/icons/sidebar/serviceCompanyActive.svg"

import LogoutIcon from "assets/images/icons/logout.svg"

import SidebarLink from "components/Shared/SidebarLink"

const SidebarContent = props => {
  const dispatch = useDispatch()
  const ref = useRef()
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()
  const { activeLinkType } = useSelector(state => state.Shared)

  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }

    if (activeLinkType === "PAYMENT_HISTORY") {
      const isMenuItem = ul.querySelector('a[href="/payment-history"]')
      if (isMenuItem) {
        activateParentDropdown(isMenuItem)
      }
    } else if (activeLinkType === "PROJECT") {
      const isMenuItem = ul.querySelector('a[href="/projects"]')
      if (isMenuItem) {
        activateParentDropdown(isMenuItem)
      }
    } else if (activeLinkType === "UNIT") {
      const isMenuItem = ul.querySelector('a[href="/units"]')
      if (isMenuItem) {
        activateParentDropdown(isMenuItem)
      }
    } else if (activeLinkType === "BANNER") {
      const isMenuItem = ul.querySelector('a[href="/banners"]')
      if (isMenuItem) {
        activateParentDropdown(isMenuItem)
      }
    } else if (activeLinkType === "NEWS") {
      const isMenuItem = ul.querySelector('a[href="/news"]')
      if (isMenuItem) {
        activateParentDropdown(isMenuItem)
      }
    } else if (activeLinkType === "CUSTOMER-SUPPORT") {
      const isMenuItem = ul.querySelector('a[href="/customer-support"]')
      if (isMenuItem) {
        activateParentDropdown(isMenuItem)
      }
    } else if (activeLinkType === "MAINTENANCE-USERS") {
      const isMenuItem = ul.querySelector('a[href="/maintenance-users"]')
      if (isMenuItem) {
        activateParentDropdown(isMenuItem)
      }
    } else if (activeLinkType === "USERS") {
      const isMenuItem = ul.querySelector('a[href="/users"]')
      if (isMenuItem) {
        activateParentDropdown(isMenuItem)
      }
    } else if (activeLinkType === "ATTRIBUTE") {
      const isMenuItem = ul.querySelector('a[href="/attributes"]')
      if (isMenuItem) {
        activateParentDropdown(isMenuItem)
      }
    } else if (activeLinkType === "SERVICES") {
      const isMenuItem = ul.querySelector('a[href="/services"]')
      if (isMenuItem) {
        activateParentDropdown(isMenuItem)
      }
    } else if (activeLinkType === "SERVICE_ITEMS") {
      const isMenuItem = ul.querySelector('a[href="/service-items"]')
      if (isMenuItem) {
        activateParentDropdown(isMenuItem)
      }
    } else if (activeLinkType === "NOTIFICATIONS") {
      const isMenuItem = ul.querySelector('a[href="/app-notifications"]')
      if (isMenuItem) {
        activateParentDropdown(isMenuItem)
      }
    } else if (activeLinkType === "HISTORY") {
      const isMenuItem = ul.querySelector('a[href="/history"]')
      if (isMenuItem) {
        activateParentDropdown(isMenuItem)
      }
    } else if (activeLinkType === "CUSTOMERS") {
      const isMenuItem = ul.querySelector('a[href="/customers"]')
      if (isMenuItem) {
        activateParentDropdown(isMenuItem)
      }
    } else if (activeLinkType === "NOTIFICATION") {
      const isMenuItem = ul.querySelector('a[href="/notifications"]')
      if (isMenuItem) {
        activateParentDropdown(isMenuItem)
      }
    } else if (activeLinkType === "CUSTOMER_SUPPORT_SUBJECT") {
      const isMenuItem = ul.querySelector('a[href="/customer-support-subject"]')
      if (isMenuItem) {
        activateParentDropdown(isMenuItem)
      }
    } else if (activeLinkType === "FAQ") {
      const isMenuItem = ul.querySelector('a[href="/faq"]')
      if (isMenuItem) {
        activateParentDropdown(isMenuItem)
      }
    } else if (activeLinkType === "SERVICE_REQUEST") {
      const isMenuItem = ul.querySelector('a[href="/service-request"]')
      if (isMenuItem) {
        activateParentDropdown(isMenuItem)
      }
    } else if (activeLinkType === "RATING_FEEDBACK") {
      const isMenuItem = ul.querySelector('a[href="/ratings-feedback"]')
      if (isMenuItem) {
        activateParentDropdown(isMenuItem)
      }
    } else if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown, activeLinkType])

  // SET ACTIVE LINK IN SIDEBAR

  const { projectId } = useParams()

  useEffect(() => {
    const pathname = path.pathname
    if (
      pathname === "/projects/create" ||
      pathname === "/projects/update" ||
      pathname === "/projects/details"
    ) {
      dispatch(showActiveLink("PROJECT"))
    } else if (
      pathname === `/units/create` ||
      pathname === `/projects/${projectId}/units/details` ||
      pathname === `/projects/${projectId}/units` ||
      pathname === `/projects/${projectId}/units/create` ||
      pathname === `/projects/${projectId}/units/update`
    ) {
      dispatch(showActiveLink("UNIT"))
    } else if (
      pathname === `/banners/create` ||
      pathname === `/banners/banner-details` ||
      pathname === `/banners/update`
    ) {
      dispatch(showActiveLink("BANNER"))
    } else if (pathname === `/customer-support/details`) {
      dispatch(showActiveLink("CUSTOMER-SUPPORT"))
    } else if (
      pathname === `/maintenance-users/create` ||
      pathname === "/maintenance-users/update" ||
      pathname === "/maintenance-users/details"
    ) {
      dispatch(showActiveLink("MAINTENANCE-USERS"))
    } else if (
      pathname === `/users/create` ||
      pathname === "/users/update" ||
      pathname === "/users/details"
    ) {
      dispatch(showActiveLink("USERS"))
    } else if (
      pathname === `/news/create` ||
      pathname === "/news/update" ||
      pathname === "/news/news-details"
    ) {
      dispatch(showActiveLink("NEWS"))
    } else if (
      pathname === `/attributes/create` ||
      pathname === "/attributes/update" ||
      pathname === "/attributes/details"
    ) {
      dispatch(showActiveLink("ATTRIBUTE"))
    } else if (
      pathname === `/services/create` ||
      pathname === "/services/update" ||
      pathname === "/services/details"
    ) {
      dispatch(showActiveLink("SERVICES"))
    } else if (
      pathname === `/service-items/create` ||
      pathname === "/service-items/update" ||
      pathname === "/service-items/details"
    ) {
      dispatch(showActiveLink("SERVICE_ITEMS"))
    } else if (pathname === "/app-notifications/details") {
      dispatch(showActiveLink("NOTIFICATIONS"))
    } else if (pathname === "/history/details") {
      dispatch(showActiveLink("HISTORY"))
    } else if (pathname === "/customers/details") {
      dispatch(showActiveLink("CUSTOMERS"))
    } else if (pathname === "/payment-history/details") {
      dispatch(showActiveLink("PAYMENT_HISTORY"))
    } else if (pathname === "/notifications/details") {
      dispatch(showActiveLink("NOTIFICATION"))
    } else if (
      pathname === "/customer-support-subject/details" ||
      pathname === "/customer-support-subject/create" ||
      pathname === "/customer-support-subject/update"
    ) {
      dispatch(showActiveLink("CUSTOMER_SUPPORT_SUBJECT"))
    } else if (pathname === "/service-request/details") {
      dispatch(showActiveLink("SERVICE_REQUEST"))
    } else if (pathname === "/ratings-feedback/details") {
      dispatch(showActiveLink("RATING_FEEDBACK"))
    } else if (
      pathname === "/faq/details" ||
      pathname === "/faq/create" ||
      pathname === "/faq/update"
    ) {
      dispatch(showActiveLink("FAQ"))
    }

    //
    else {
      dispatch(showActiveLink(""))
    }
  }, [path.pathname])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }
  const { userType } = useSelector(state => state.Login)

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {userType === "mowatheq" ? null : (
              <li>
                <SidebarLink
                  to="/dashboard"
                  icon={DashboardIcon}
                  activeIcon={DashboardIconActive}
                  label={props.t("Dashboards")}
                />
              </li>
            )}

            <li>
              <SidebarLink
                to="/profile"
                icon={ProfileIcon}
                activeIcon={ProfileIconActive}
                label={props.t("Profile")}
              />
            </li>

            {userType === "superadmin" && (
              <>
                <li>
                  <SidebarLink
                    to="/notifications"
                    icon={NotificationIcon}
                    activeIcon={NotificationActiveIcon}
                    label={props.t("Notifications")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/maintenance-users"
                    icon={MaintenanceUserIcon}
                    activeIcon={MaintenanceUserActiveIcon}
                    label={props.t("Maintenance Users")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/service-request"
                    icon={MaintenanceUserIcon}
                    activeIcon={MaintenanceUserActiveIcon}
                    label={props.t("Service Request")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/service-company"
                    icon={ServiceCompany}
                    activeIcon={ServiceCompanyActive}
                    label={props.t("Service Company")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/users"
                    icon={UserIcon}
                    activeIcon={UserActiveIcon}
                    label={props.t("Users")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/ratings-feedback"
                    icon={RatingIcon}
                    activeIcon={RatingActiveIcon}
                    label={props.t("Ratings & Feedback")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/customer-support"
                    icon={customerSupportIcon}
                    activeIcon={customerSupportActiveIcon}
                    label={props.t("Customer Support")}
                  />
                </li>
              </>
            )}
            {userType === "sales" && (
              <>
                <li>
                  <SidebarLink
                    to="/notifications"
                    icon={NotificationIcon}
                    activeIcon={NotificationActiveIcon}
                    label={props.t("Notifications")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/projects"
                    icon={ProjectsIcon}
                    activeIcon={ProjectsActiveIcon}
                    label={props.t("Projects")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/units"
                    icon={UnitIcon}
                    activeIcon={UnitActiveIcon}
                    label={props.t("Units")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/delivery-letter"
                    icon={DeliveryReqIcon}
                    activeIcon={DeliveryReqActiveIcon}
                    label={props.t("Delivery Letter")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/water-and-bill-submission"
                    icon={waterBillIcon}
                    activeIcon={waterBillActiveIcon}
                    label={props.t("Water & Bill Submissions")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/deed-records"
                    icon={DeedReqIcon}
                    activeIcon={DeedReqActiveIcon}
                    label={props.t("Deed Records")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/history"
                    icon={historyIcon}
                    activeIcon={historyActiveIcon}
                    label={props.t("History")}
                  />
                </li>
                {/* <li>
                  <SidebarLink
                    to="/customer-support"
                    icon={customerSupportIcon}
                    activeIcon={customerSupportActiveIcon}
                    label={props.t("Customer Support")}
                  />
                </li> */}
                {/* <li>
                  <SidebarLink
                    to="/customer-support-subject"
                    icon={CustomerSupportSubjectIcon}
                    activeIcon={CustomerSupportSubjectActiveIcon}
                    label={props.t("Customer Support Subject")}
                  />
                </li> */}
              </>
            )}

            {userType === "mowatheq" && (
              <>
                <li>
                  <SidebarLink
                    to="/notifications"
                    icon={NotificationIcon}
                    activeIcon={NotificationActiveIcon}
                    label={props.t("Notifications")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/deed-request"
                    icon={DeedReqIcon}
                    activeIcon={DeedReqActiveIcon}
                    label={props.t("Deed Request")}
                  />
                </li>
              </>
            )}

            {userType === "marketing" && (
              <>
                <li>
                  <SidebarLink
                    to="/banners"
                    icon={BannerIcon}
                    activeIcon={BannerActiveIcon}
                    label={props.t("Banners")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/cities"
                    icon={CityIcon}
                    activeIcon={CityActiveIcon}
                    label={props.t("Cities")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/projects"
                    icon={ProjectsIcon}
                    activeIcon={ProjectsActiveIcon}
                    label={props.t("Projects")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/units"
                    icon={UnitIcon}
                    activeIcon={UnitActiveIcon}
                    label={props.t("Units")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/attributes"
                    icon={AttributeIcon}
                    activeIcon={AttributeActiveIcon}
                    label={props.t("Attributes")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/services"
                    icon={ServiceIcon}
                    activeIcon={ServiceActiveIcon}
                    label={props.t("Services")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/service-items"
                    icon={ServiceItemIcon}
                    activeIcon={ServiceItemActiveIcon}
                    label={props.t("Service Items")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/room-types"
                    icon={RoomIcon}
                    activeIcon={RoomActiveIcon}
                    label={props.t("Room Types")}
                  />
                </li>

                <li>
                  <SidebarLink
                    to="/app-notifications"
                    icon={NotificationIcon}
                    activeIcon={NotificationActiveIcon}
                    label={props.t("App Notifications")}
                  />
                </li>

                <li>
                  <SidebarLink
                    to="/#"
                    icon={NotificationControlIcon}
                    activeIcon={NotificationControlActiveIcon}
                    label={props.t("Notification Control")}
                    className="has-arrow"
                  />

                  <ul className="sub-menu">
                    <li>
                      <Link to="/push-notifications">
                        {props.t("Push Notifications")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/sms-notifications">
                        {props.t("SMS Notifications")}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <SidebarLink
                    to="/partners"
                    icon={PartnerIcon}
                    activeIcon={PartnerActiveIcon}
                    label={props.t("Partners")}
                  />
                </li>

                <li>
                  <SidebarLink
                    to="/news"
                    icon={NewsIcon}
                    activeIcon={NewsActiveIcon}
                    label={props.t("News")}
                  />
                </li>

                <li>
                  <SidebarLink
                    to="/app-look-and-feel"
                    icon={AppLookFeel}
                    activeIcon={AppLookFeelActive}
                    label={props.t("App Look & Feel")}
                  />
                </li>

                <li>
                  <SidebarLink
                    to="/faq"
                    icon={NewsIcon}
                    activeIcon={NewsActiveIcon}
                    label={props.t("FAQ")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/terms-and-conditions"
                    icon={TermsIcon}
                    activeIcon={TermsActiveIcon}
                    label={props.t("Terms And Conditions")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/privacy-policy"
                    icon={PrivacyIcon}
                    activeIcon={PrivacyActiveIcon}
                    label={props.t("Privacy Policy")}
                  />
                </li>

                {/* <li>
                  <SidebarLink
                    to="/customer-support"
                    icon={customerSupportIcon}
                    activeIcon={customerSupportActiveIcon}
                    label={props.t("Customer Support")}
                  />
                </li> */}

                <li>
                  <SidebarLink
                    to="/#"
                    icon={GlobalSettingsIcon}
                    activeIcon={GlobalSettingsActiveIcon}
                    label={props.t("Global Settings")}
                    className="has-arrow"
                  />

                  <ul className="sub-menu">
                    <li>
                      <Link to="/external-links">
                        {props.t("External Links")}
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <SidebarLink
                    to="/customer-support-subject"
                    icon={CustomerSupportSubjectIcon}
                    activeIcon={CustomerSupportSubjectActiveIcon}
                    label={props.t("Customer Support Subject")}
                  />
                </li> */}
              </>
            )}

            {userType === "finance" && (
              <>
                <li>
                  <SidebarLink
                    to="/notifications"
                    icon={NotificationIcon}
                    activeIcon={NotificationActiveIcon}
                    label={props.t("Notifications")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/down-payments"
                    icon={DownPaymentIcon}
                    activeIcon={DownPaymentActiveIcon}
                    label={props.t("Down Payments")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/full-payments"
                    icon={FullPaymentIcon}
                    activeIcon={FullPaymentActiveIcon}
                    label={props.t("Full Payments")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/tax-payments"
                    icon={TaxIcon}
                    activeIcon={TaxActiveIcon}
                    label={props.t("Tax Payments")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/payment-contracts"
                    icon={PaymentContractIcon}
                    activeIcon={PaymentContractActiveIcon}
                    label={props.t("Payment Contracts")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/rett-certificate"
                    icon={RETTIcon}
                    activeIcon={RETTActiveIcon}
                    label={props.t("RETT Certificate")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/payment-receipt"
                    icon={PaymentReceiptIcon}
                    activeIcon={PaymentReceiptActiveIcon}
                    label={props.t("Warranty Receipt")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/payment-history"
                    icon={PaymentHistoryIcon}
                    activeIcon={PaymentHistoryActiveIcon}
                    label={props.t("Payment History")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/#"
                    icon={ReportsIcon}
                    activeIcon={ReportsActiveIcon}
                    label={props.t("Reports")}
                    className="has-arrow"
                  />
                  <ul className="sub-menu">
                    <li>
                      <Link to="/sales-reports">
                        {props.t("Sales Reports")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/payment-reports">
                        {props.t("Payment Reports")}
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <SidebarLink
                    to="/complaints"
                    icon={ComplaintsIcon}
                    activeIcon={ComplaintsActiveIcon}
                    label={props.t("Complaints")}
                  />
                </li> */}
                {/* <li>
                  <SidebarLink
                    to="/customer-support"
                    icon={customerSupportIcon}
                    activeIcon={customerSupportActiveIcon}
                    label={props.t("Customer Support")}
                  />
                </li> */}
                {/* <li>
                  <SidebarLink
                    to="/customer-support-subject"
                    icon={CustomerSupportSubjectIcon}
                    activeIcon={CustomerSupportSubjectActiveIcon}
                    label={props.t("Customer Support Subject")}
                  />
                </li> */}
              </>
            )}

            {userType === "customerService" && (
              <>
                {/* <li>
                  <SidebarLink
                    to="/customer-support"
                    icon={customerSupportIcon}
                    activeIcon={customerSupportActiveIcon}
                    label={props.t("Customer Support")}
                  />
                </li> */}
                <li>
                  <SidebarLink
                    to="/customer-support-subject"
                    icon={CustomerSupportSubjectIcon}
                    activeIcon={CustomerSupportSubjectActiveIcon}
                    label={props.t("Customer Support Subject")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/projects"
                    icon={ProjectsIcon}
                    activeIcon={ProjectsActiveIcon}
                    label={props.t("Projects")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/units"
                    icon={UnitIcon}
                    activeIcon={UnitActiveIcon}
                    label={props.t("Units")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/#"
                    icon={NotificationIcon}
                    activeIcon={NotificationActiveIcon}
                    label={props.t("Notification Control")}
                    className="has-arrow"
                  />

                  <ul className="sub-menu">
                    <li>
                      <Link to="/push-notifications">
                        {props.t("Push Notifications")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/sms-notifications">
                        {props.t("SMS Notifications")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <SidebarLink
                    to="/registered-interests"
                    icon={RegisteredIcon}
                    activeIcon={RegisteredActiveIcon}
                    label={props.t("Registered Interests")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/customers"
                    icon={customersIcon}
                    activeIcon={customersActiveIcon}
                    label={props.t("Customers")}
                  />
                </li>
                <li>
                  <SidebarLink
                    to="/qr-code"
                    icon={QRIcon}
                    activeIcon={QRActiveIcon}
                    label={props.t("QR Code")}
                  />
                </li>
              </>
            )}
            {/* {hasAccess(userType) && (
              <>
                <li>
                  <Link to="/maintenance-users">
                    <i className="bx bxs-wrench"></i>
                    <span>{props.t("Maintenance Users")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/users">
                    <i className="bx bxs-user"></i>
                    <span>{props.t("Users")}</span>
                  </Link>
                </li>
              </>
            )} */}

            {/* <li>
              <Link to="/customers">
                <i className="mdi mdi-account-group"></i>
                <span>{props.t("Customers")}</span>
              </Link>
            </li> */}

            {/* <li>
              <Link to="/activity">
                <i className="mdi mdi-chart-areaspline-variant"></i>
                <span>{props.t("Activity")}</span>
              </Link>
            </li> */}

            {/* SINGLE LI */}
            {/* <li>
              <Link to="#">
                <i className="bx bx-file"></i>
                <span>{props.t("File Manager")}</span>
              </Link>
            </li> */}

            {/* MULTIPLE LI */}
            {/* <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-detail" />

                <span>{props.t("Blog")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("Blog List")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Blog Grid")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Blog Details")}</Link>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>

        {/* <Link to="/logout" className="sidebar_logout_link">
          <img src={LogoutIcon} alt="logout" />
          <span className="danger_color">{props.t("Logout")}</span>
        </Link> */}
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
