import React, { useEffect } from "react"
import { Card, Col, Container, Row, Table } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import useFilters from "components/Shared/useFilters"
import Title from "components/Shared/Title"
import Filters from "components/Shared/Filters"
import { commonFilterOptions } from "components/Common/constants"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import Switch from "components/Shared/Switch"
import { getDateRange, handleStopPropagation } from "components/Common/Utils"
import DeleteModal from "components/Common/DeleteModal"
import Paginations from "components/Common/Pagination"
import TrashIcon from "assets/images/icons/trash.svg"
import EditIcon from "assets/images/icons/edit.svg"
import TopTitle from "components/Shared/TopTitle"
import { useDispatch, useSelector } from "react-redux"

import Spinners from "components/Shared/Spinners"
import { showAlert } from "store/reducers/alertReducer"
import {
  deleteService,
  getAllServices,
  syncServiceWithERP,
  updateServiceStatus,
} from "store/actions/servicesAction"

const Services = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Services | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const {
    loading,
    isLoading,
    services,
    totalCount,
    totalPages,
    currentPage,
    perPage,
  } = useSelector(state => state.Services)

  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    status: -1,
    orderBy: 2,
    type: [],
  })

  useEffect(() => {
    fetchAllServices()
  }, [])

  const fetchAllServices = page => {
    const { status, orderBy, type } = filters
    const updatedType = type?.map(item => item.value) || []

    const payload = {
      perPage: perPage,
      pageNo: page || pageNumber,
      status: status,
      orderBy,
      type: updatedType,
      keyword,
      dateRange: getDateRange(dateRange),
    }
    dispatch(getAllServices(payload))
    setIsApiCalled(true)

    navigate(`/services?page=${page || pageNumber}`)
  }

  // RECALL API ONCHANGE FILTER
  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchAllServices(1)
  }

  const onPageChange = page => {
    fetchAllServices(page)
  }

  const handleStatusClick = (id, status) => {
    setSelectedId(id)
    const payload = {
      id,
      status: status === 1 ? "0" : "1",
    }
    dispatch(updateServiceStatus(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setSelectedId(null)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const handleDelete = () => {
    const payload = {
      id: itemIdToDelete,
      status: "2", // 2 FOR DELETE
    }
    dispatch(deleteService(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const handleSyncServices = () => {
    dispatch(syncServiceWithERP())
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          handleSearch()
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("Services")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Marketing portal!`,
              { name: name }
            )}
            createBtnText={t("Create")}
            onCreateBtnClick={() => {
              navigate("/services/create")
            }}
            secondaryBtnText={t("Sync Services")}
            isLoading={isLoading}
            onSecondaryBtnClick={handleSyncServices}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <Title title={t("Services List")} />
                <div className="pb-2"></div>
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={commonFilterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                />
                {loading ? (
                  <Spinners />
                ) : services.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="full_th">
                        <tr>
                          <th>{t("# ")}</th>
                          <th>{t("Image")}</th>
                          <th>{t("Name")}</th>
                          <th>{t("Status")}</th>
                          <th>{t("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {services.map((item, index) => (
                          <tr
                            key={item._id}
                            className="cursor-pointer"
                            onClick={() => {
                              navigate(`/services/details?id=${item._id}`)
                            }}
                          >
                            <th scope="row">
                              {(currentPage - 1) * perPage + index + 1}
                            </th>
                            <td>
                              {item.imageUrl ? (
                                <img
                                  src={item.imageUrl}
                                  alt=""
                                  width={24}
                                  height={24}
                                  className="img-fluid"
                                />
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              {language === "sa"
                                ? item?.nameAr
                                : item?.name || "-"}
                            </td>
                            {/* <td>
                              {t(item.type)}
                            </td> */}
                            <td>
                              {item.status === 1 ? (
                                <p className="rended_status status_tag">
                                  {t("Active")}
                                </p>
                              ) : item.status === 0 ? (
                                <p className="reject_status status_tag">
                                  {t("Deactive")}
                                </p>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td onClick={handleStopPropagation}>
                              <div className="d-flex align-items-center">
                                <img
                                  src={EditIcon}
                                  alt="Edit Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer"
                                  onClick={() =>
                                    navigate(`/services/update?id=${item._id}`)
                                  }
                                />
                                <img
                                  src={TrashIcon}
                                  alt="Trash Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer mx-3"
                                  onClick={() => {
                                    setItemIdToDelete(item._id)
                                    setShowDeleteModal(true)
                                  }}
                                />
                                <Switch
                                  onClickHandler={() => {
                                    handleStatusClick(item._id, item.status)
                                  }}
                                  isChecked={item.status === 1 ? true : false}
                                  disabled={
                                    isLoading && selectedId === item._id
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && services.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </Row>
        </Container>
      </div>
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        }}
        isLoading={isLoading}
        buttonText={t("Delete")}
        bodyTitle={t("Are you sure you want to delete this {{name}}?", {
          name: t("Service"),
        })}
      />
    </React.Fragment>
  )
}

export default Services
