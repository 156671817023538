import React, { useEffect, useState } from "react"
import { Card, Col, Container, Modal, Row, Table } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import useFilters from "components/Shared/useFilters"
import Title from "components/Shared/Title"
import Filters from "components/Shared/Filters"
import { commonFilterOptions } from "components/Common/constants"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import Switch from "components/Shared/Switch"
import { getDateRange, handleStopPropagation } from "components/Common/Utils"
import DeleteModal from "components/Common/DeleteModal"
import Paginations from "components/Common/Pagination"
import TrashIcon from "assets/images/icons/trash.svg"
import EditIcon from "assets/images/icons/edit.svg"
import TopTitle from "components/Shared/TopTitle"
import { useDispatch, useSelector } from "react-redux"

import Spinners from "components/Shared/Spinners"
import { showAlert } from "store/reducers/alertReducer"
import {
  createServiceCompany,
  deleteService,
  deleteServiceCompany,
  getAllServiceCompany,
  getAllServices,
  getServiceCompanyDetails,
  syncServiceWithERP,
  updateServiceCompany,
  updateServiceCompanyStatus,
  updateServiceStatus,
} from "store/actions/servicesAction"
import { Field, Form, Formik } from "formik"
import CustomLabel from "components/Shared/CustomLabel"
import CustomField from "components/Shared/CustomField"
import { createServiceCompanySchema } from "components/Common/validation"
import IsLoading from "components/Shared/IsLoading"

const initialValues = {
  name: "",
  address: "",
}

const ServiceCompany = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Service Company | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const [formValues, setFormValues] = useState(initialValues)

  const {
    loading,
    isLoading,
    serviceCompany,
    serviceCompanyDetails,
    gettingDetails,
    totalCount,
    totalPages,
    currentPage,
    perPage,
  } = useSelector(state => state.Services)

  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    status: -1,
    orderBy: 2,
  })

  const [modal, setModal] = useState(false)
  const [modalType, setModalType] = useState(null)

  const toggle = () => {
    if (modal) {
      setSelectedId(null)
      setModalType(null)
      setFormValues(initialValues)
    }
    setModal(!modal)
  }

  useEffect(() => {
    fetchAllServiceCompany()
  }, [])

  const fetchAllServiceCompany = page => {
    const { status, orderBy } = filters

    const payload = {
      perPage: perPage,
      pageNo: page || pageNumber,
      status: status,
      orderBy,
      keyword,
      dateRange: getDateRange(dateRange),
    }
    dispatch(getAllServiceCompany(payload))
    setIsApiCalled(true)

    navigate(`/service-company?page=${page || pageNumber}`)
  }

  // RECALL API ONCHANGE FILTER
  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchAllServiceCompany(1)
  }

  const onPageChange = page => {
    fetchAllServiceCompany(page)
  }

  useEffect(() => {
    const getDetails = () => {
      const payload = {
        id: selectedId,
      }
      dispatch(getServiceCompanyDetails(payload))
    }
    if (modalType === "EDIT") {
      getDetails()
    }
    console.log("first")
  }, [modalType])

  // SET FORM FIELD
  useEffect(() => {
    if (serviceCompanyDetails && !gettingDetails && modalType === "EDIT") {
      const { name, address } = serviceCompanyDetails || {}
      setFormValues({ name, address })
    }
  }, [serviceCompanyDetails, gettingDetails])

  const handleStatusClick = (id, status) => {
    setSelectedId(id)
    const payload = {
      id,
      status: status === 1 ? "0" : "1",
    }
    dispatch(updateServiceCompanyStatus(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setSelectedId(null)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const handleDelete = () => {
    const payload = {
      id: itemIdToDelete,
      status: "2", // 2 FOR DELETE
    }
    dispatch(deleteServiceCompany(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const handleOnSubmit = (values, { resetForm }) => {
    if (modalType === "ADD") {
      dispatch(createServiceCompany(values))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
            toggle()
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {})
    } else if (modalType === "EDIT") {
      const updatedPayload = {
        ...values,
        id: selectedId,
      }
      dispatch(updateServiceCompany(updatedPayload))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            dispatch(showAlert({ text: message, type: "success" }))
            toggle()
          } else {
            dispatch(showAlert({ text: message, type: "error" }))
          }
        })
        .catch(error => {})
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("Service Company")}
            subTitle={t(`Hi, {{name}}. Welcome back to your Admin portal!`, {
              name: name,
            })}
            createBtnText={t("Create")}
            onCreateBtnClick={() => {
              setModalType("ADD")
              toggle()
            }}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <Title title={t("Service Company")} />
                <div className="pb-2"></div>
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={commonFilterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                />
                {loading ? (
                  <Spinners />
                ) : serviceCompany.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="full_th">
                        <tr>
                          <th>{t("# ")}</th>
                          <th>{t("Name")}</th>
                          <th>{t("Address")}</th>
                          <th>{t("Status")}</th>
                          <th>{t("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {serviceCompany.map((item, index) => (
                          <tr
                            key={item._id}
                            className="cursor-pointer"
                            onClick={() => {
                              //   navigate(`/services/details?id=${item._id}`)
                            }}
                          >
                            <th scope="row">
                              {(currentPage - 1) * perPage + index + 1}
                            </th>
                            <td>{item?.name || "-"}</td>
                            <td>{item?.address || "-"}</td>
                            <td>
                              {item.status === 1 ? (
                                <p className="rended_status status_tag">
                                  {t("Active")}
                                </p>
                              ) : item.status === 0 ? (
                                <p className="reject_status status_tag">
                                  {t("Deactive")}
                                </p>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td onClick={handleStopPropagation}>
                              <div className="d-flex align-items-center">
                                <img
                                  src={EditIcon}
                                  alt="Edit Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setSelectedId(item?._id)
                                    setModalType("EDIT")
                                    toggle()
                                  }}
                                />
                                <img
                                  src={TrashIcon}
                                  alt="Trash Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer mx-3"
                                  onClick={() => {
                                    setItemIdToDelete(item._id)
                                    setShowDeleteModal(true)
                                  }}
                                />
                                <Switch
                                  onClickHandler={() => {
                                    handleStatusClick(item._id, item.status)
                                  }}
                                  isChecked={item.status === 1 ? true : false}
                                  disabled={
                                    isLoading && selectedId === item._id
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
          {!loading && serviceCompany.length > 0 && (
            <Paginations
              currentPage={currentPage}
              totalPages={totalPages}
              totalCount={totalCount}
              onPageChange={onPageChange}
            />
          )}
        </Container>
      </div>
      <Modal isOpen={modal} toggle={toggle} centered className={`g_modal `}>
        <h2 className="modal_title">
          {modalType === "ADD"
            ? t("Add Service Company")
            : t("Update Service Company")}
        </h2>
        <button type="button" onClick={toggle} className="modal_close_btn">
          <i className="mdi mdi-close"></i>
        </button>
        {gettingDetails ? (
          <div className="pt-4">
            <IsLoading />
          </div>
        ) : (
          <Formik
            initialValues={formValues}
            enableReinitialize={true}
            validationSchema={createServiceCompanySchema}
            onSubmit={handleOnSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <Row>
                  <Col lg={12}>
                    <div className="form_field">
                      <CustomLabel
                        htmlFor="name"
                        label={t("Name")}
                        isRequired={true}
                      />
                      <CustomField
                        name="name"
                        type="text"
                        placeholder={t("Name")}
                        touched={touched}
                        errors={errors}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form_field">
                      <CustomLabel htmlFor="address" label={t("Address")} />
                      <Field
                        as="textarea"
                        id="address"
                        name="address"
                        rows={4}
                        placeholder={t("Address")}
                        className={`form-control b_r_12 ${
                          errors.address && touched.address ? "is-invalid" : ""
                        }`}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="d-flex justify-content-end mt-3">
                      <button
                        type="button"
                        className="cancel_btn mx-2"
                        onClick={toggle}
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        className="primary_btn"
                        type="submit"
                        disabled={isLoading}
                      >
                        {modalType === "ADD" ? t("Create") : t("Update")}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        }}
        isLoading={isLoading}
        buttonText={t("Delete")}
        bodyTitle={t("Are you sure you want to delete this {{name}}?", {
          name: t("Service Company"),
        })}
      />
    </React.Fragment>
  )
}

export default ServiceCompany
