import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  del,
  formDataPost,
  formDataPut,
  get,
  patch,
  post,
  put,
} from "helpers/api_helper"
import {
  CREATE_SERVICE,
  CREATE_SERVICE_COMPANY,
  DELETE_SERVICE,
  DELETE_SERVICE_COMPANY,
  GET_ALL_SERVICE,
  GET_ALL_SERVICE_COMPANY,
  GET_SERVICE_COMPANY_DETAILS,
  GET_SERVICE_COMPANY_LIST,
  GET_SERVICE_DETAILS,
  GET_SERVICES_LIST,
  SYNC_SERVICE_WITH_ERP,
  UPDATE_SERVICE,
  UPDATE_SERVICE_COMPANY,
  UPDATE_SERVICE_COMPANY_STATUS,
  UPDATE_SERVICE_STATUS,
} from "helpers/url_helper"

export const getAllServices = createAsyncThunk(
  GET_ALL_SERVICE,
  async payload => {
    const response = await post(GET_ALL_SERVICE, payload)
    return response
  }
)

export const getServicesList = createAsyncThunk(
  GET_SERVICES_LIST,
  async payload => {
    const response = await post(GET_SERVICES_LIST, payload)
    return response
  }
)

export const getServiceDetails = createAsyncThunk(
  GET_SERVICE_DETAILS,
  async payload => {
    const response = await post(GET_SERVICE_DETAILS, payload)
    return response
  }
)

export const updateServiceStatus = createAsyncThunk(
  UPDATE_SERVICE_STATUS,
  async payload => {
    const response = await patch(UPDATE_SERVICE_STATUS, payload)
    return response
  }
)

export const deleteService = createAsyncThunk(DELETE_SERVICE, async payload => {
  const response = await del(DELETE_SERVICE, payload)
  return response
})

export const createService = createAsyncThunk(CREATE_SERVICE, async payload => {
  const response = await formDataPost(CREATE_SERVICE, payload)
  return response
})

export const updateService = createAsyncThunk(UPDATE_SERVICE, async payload => {
  const response = await formDataPut(UPDATE_SERVICE, payload)
  return response
})

export const syncServiceWithERP = createAsyncThunk(
  SYNC_SERVICE_WITH_ERP,
  async () => {
    const response = await get(SYNC_SERVICE_WITH_ERP)
    return response
  }
)

export const getAllServiceCompany = createAsyncThunk(
  GET_ALL_SERVICE_COMPANY,
  async payload => {
    const response = await post(GET_ALL_SERVICE_COMPANY, payload)
    return response
  }
)

export const getServiceCompanyList = createAsyncThunk(
  GET_SERVICE_COMPANY_LIST,
  async () => {
    const response = await get(GET_SERVICE_COMPANY_LIST)
    return response
  }
)

export const getServiceCompanyDetails = createAsyncThunk(
  GET_SERVICE_COMPANY_DETAILS,
  async payload => {
    const response = await post(GET_SERVICE_COMPANY_DETAILS, payload)
    return response
  }
)

export const updateServiceCompanyStatus = createAsyncThunk(
  UPDATE_SERVICE_COMPANY_STATUS,
  async payload => {
    const response = await patch(UPDATE_SERVICE_COMPANY_STATUS, payload)
    return response
  }
)

export const deleteServiceCompany = createAsyncThunk(
  DELETE_SERVICE_COMPANY,
  async payload => {
    const response = await del(DELETE_SERVICE_COMPANY, payload)
    return response
  }
)

export const createServiceCompany = createAsyncThunk(CREATE_SERVICE_COMPANY, async payload => {
  const response = await post(CREATE_SERVICE_COMPANY, payload)
  return response
})

export const updateServiceCompany = createAsyncThunk(UPDATE_SERVICE_COMPANY, async payload => {
  const response = await put(UPDATE_SERVICE_COMPANY, payload)
  return response
})
