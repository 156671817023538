import React, { useEffect, useState } from "react"
import {
  Card,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Table,
  Modal,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import useFilters from "components/Shared/useFilters"
import Title from "components/Shared/Title"
import Filters from "components/Shared/Filters"
import {
  ascDescOptions,
  serviceRequestStatus,
  serviceRequestType,
} from "components/Common/constants"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import Switch from "components/Shared/Switch"
import {
  getDateRange,
  handleStopPropagation,
  unitName,
} from "components/Common/Utils"
import Paginations from "components/Common/Pagination"
import DeleteModal from "components/Common/DeleteModal"
import TopTitle from "components/Shared/TopTitle"
import TrashIcon from "assets/images/icons/trash.svg"
import EditIcon from "assets/images/icons/edit.svg"
import { useDispatch, useSelector } from "react-redux"
import {
  assignServiceProvider,
  cancelServiceRequest,
  deleteMaintenanceUser,
  getAllMaintenanceUsers,
  getAllServiceRequest,
  getAvailableServiceProvider,
  getMaintenanceUsersList,
  updateMaintenanceUserStatus,
} from "store/actions/maintenanceUsersAction"
import Spinners from "components/Shared/Spinners"
import { getServiceItemList } from "store/actions/serviceItemsAction"
import { showAlert } from "store/reducers/alertReducer"
import FormateDate, {
  FormateOnlyDate,
  FormateOnlyTime,
} from "components/Shared/FormateDate"
import classNames from "classnames"
import { getUnitsList } from "store/actions/unitsAction"
import { getServicesList } from "store/actions/servicesAction"
import { getCustomersList } from "store/actions/customersAction"
import Select from "react-select"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import CustomLabel from "components/Shared/CustomLabel"
import { Form, Formik } from "formik"
import { assignServiceProviderSchema } from "components/Common/validation"

const ServiceRequest = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Service Request | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { search } = useLocation()
  const urlParams = new URLSearchParams(search)

  const pageNumber = Number(urlParams.get("page")) || 1
  const defaultActiveTab = Number(urlParams.get("tab")) ?? -1

  const {
    loading,
    isLoading,
    maintenanceServiceRequest,
    totalCount,
    totalPages,
    currentPage,
    perPage,
    maintenanceUsersList,
    availableServiceProvider,
  } = useSelector(state => state.MaintenanceUsers)

  const { serviceItemsList } = useSelector(state => state.ServiceItems)
  const { unitsList } = useSelector(state => state.Units)
  const { servicesList } = useSelector(state => state.Services)
  const { customersList } = useSelector(state => state.Customers)

  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    status: -1,
    orderBy: 2,
    service: [],
    unit: [],
    customer: [],
    serviceProvider: [],
  })

  const [activeTab, setActiveTab] = useState(defaultActiveTab)
  const [modal, setModal] = useState(false)

  useEffect(() => {
    // Extract query parameters 'keyword' and 'tab' from the URL
    const urlParams = new URLSearchParams(location.search)
    const tabFromUrl = urlParams.get("tab")
    // Update state for 'tab'
    if (tabFromUrl) {
      const parsedTab = parseInt(tabFromUrl, 10)
      if (!isNaN(parsedTab) && parsedTab !== activeTab) {
        setActiveTab(parsedTab)
      }
    }
  }, [location.search])

  const toggleModal = () => {
    modal && setSelectedId(null)
    setModal(!modal)
  }

  const additionOptions = [
    ...(activeTab === -1
      ? [
          {
            name: "status",
            type: "select",
            options: serviceRequestStatus?.map(item => ({
              label: t(item.label),
              value: item.value,
            })),
            placeholder: t("All"),
          },
        ]
      : []),
    // {
    //   name: "service",
    //   type: "multiSelect",
    //   options: serviceItemsList?.map(item => ({
    //     label: language === "sa" ? item.nameAr : item.name,
    //     value: item._id,
    //   })),
    //   placeholder: t("Select Service Items"),
    // },
    {
      name: "unit",
      type: "multiSelect",
      options: unitsList.map(item => ({
        label: unitName(item),
        value: item._id,
      })),
      placeholder: t("Select Units"),
    },
    {
      name: "service",
      type: "multiSelect",
      options: servicesList.map(item => ({
        label: language === "sa" ? item.nameAr : item.name,
        value: item._id,
      })),
      placeholder: t("Select Services"),
    },

    ...(activeTab !== 0 && activeTab !== 1
      ? [
          {
            name: "serviceProvider",
            type: "multiSelect",
            options: maintenanceUsersList.map(item => ({
              label: item.name,
              value: item._id,
            })),
            placeholder: t("Select Service Provider"),
          },
        ]
      : []),
    {
      name: "customer",
      type: "multiSelect",
      options: customersList.map(item => ({
        label: `${item.name || ""} ${item.phone || ""}`,
        value: item._id,
      })),
      placeholder: t("Type Customers name"),
    },
  ]

  useEffect(() => {
    fetchAllServiceRequest()
    fetchServiceItemsList()
    fetchUnitsList()
    fetchServicesList()
    fetchServiceProviders()
  }, [])

  const fetchServiceItemsList = () => {
    dispatch(getServiceItemList())
  }

  const fetchAllServiceRequest = page => {
    const { status, orderBy, service, unit, customer, serviceProvider } =
      filters

    const updatedStatus = activeTab === -1 ? status : activeTab

    const updatedServices = service?.map(item => item.value) || []
    const updatedUnits = unit?.map(item => item.value) || []
    const updatedCustomers = customer?.map(item => item.value) || []
    const updatedServiceProvider =
      serviceProvider?.map(item => item.value) || []

    const payload = {
      perPage: perPage,
      pageNo: page || pageNumber,
      status: Number(updatedStatus),
      orderBy,
      service: updatedServices,
      keyword,
      dateRange: getDateRange(dateRange),
      unit: updatedUnits,
      customer: updatedCustomers,
      serviceItem: [],
      serviceProvider: updatedServiceProvider,
      dateRangeScheduled: {},
    }
    dispatch(getAllServiceRequest(payload))
    setIsApiCalled(true)

    // Update the URL
    const url = new URLSearchParams()
    if (keyword) url.set("keyword", keyword)
    if (activeTab !== -1) url.set("tab", activeTab)
    if (page) url.set("page", page)

    navigate(`/service-request?${url.toString()}`)

    // navigate(`/service-request?page=${page || pageNumber}`)
  }

  const fetchUnitsList = () => {
    const payload = {
      project: [],
      statusType: [1, 2, 3, 4],
    }
    dispatch(getUnitsList(payload))
  }

  const fetchServicesList = () => {
    const payload = {
      type: ["features"],
    }
    dispatch(getServicesList(payload))
  }

  const fetchServiceProviders = () => {
    const payload = {
      service: [],
    }
    dispatch(getMaintenanceUsersList(payload))
  }

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
      setKeyword("")
    }
  }

  const fetchAvailableServiceProvider = id => {
    const payload = {
      id, // SERVICE REQUEST ID
    }
    dispatch(getAvailableServiceProvider(payload))
  }

  const filterOptions = [...ascDescOptions, ...additionOptions]

  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters, activeTab])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchAllServiceRequest(1)
  }

  const onPageChange = page => {
    fetchAllServiceRequest(page)
  }

  const commonColumns = [
    { header: "#", accessor: "serialNo" },
    { header: t("Request Number"), accessor: "requestNo" },
    { header: t("Customer Name"), accessor: "userData.name" },
    { header: t("Customer Phone Number"), accessor: "userData.phone" },
    {
      header: t("Service Name"),
      accessor: language === "sa" ? "serviceData.nameAr" : "serviceData.name",
    },
    {
      header: t("Unit Name"),
      accessor: language === "sa" ? "unitData.nameAr" : "unitData.name",
    },
    { header: t("Request Type"), accessor: "requestType" },
    { header: t("Schedule Date"), accessor: "scheduledDate" },
    { header: t("Schedule Time"), accessor: "scheduledTime" },
  ]

  const columnsByTab = {
    "-1": [
      // ALL
      { header: t("Price Details"), accessor: "price" },
      { header: t("Payment Status"), accessor: "isPaymentDone" },
      { header: t("Provider Name"), accessor: "scheduledTime" },
      { header: t("Started Time"), accessor: "startedAt" },
      { header: t("Completed Time"), accessor: "completedAt" },
    ],
    0: [
      // REQUESTED
      { header: t("Price Details"), accessor: "price" },
      { header: t("Payment Status"), accessor: "isPaymentDone" },
      { header: t("Action"), accessor: "action" },
    ],
    1: [
      // PENDING
      { header: t("Price Details"), accessor: "price" },
      { header: t("Payment Status"), accessor: "isPaymentDone" },
      { header: t("Action"), accessor: "action" },
    ],
    2: [
      //ASSIGNED
      { header: t("Provider Name"), accessor: "serviceProviderData.name" },
    ],
    3: [
      // IN PROGRESS
      { header: t("Provider Name"), accessor: "serviceProviderData.name" },
      { header: t("Started Time"), accessor: "startedAt" },
    ],
    4: [
      //COMPLETED
      { header: t("Provider Name"), accessor: "serviceProviderData.name" },
      { header: t("Completed Time"), accessor: "completedAt" },
    ],
  }

  const updatedTab = activeTab === -1 ? Number(filters.status) : activeTab

  const getColumns = () => [
    ...commonColumns,
    ...(columnsByTab[updatedTab] || []),
  ]

  const renderCell = (item, accessor, index) => {
    // console.log("Rendering:", { accessor, value: item[accessor] })

    if (accessor === "serialNo") {
      return index + 1 // Serial number
    }

    if (accessor === "action") {
      return (
        <div className="fit_content" onClick={handleStopPropagation}>
          {/* Cancel Button for tabs 0, 1, and 2 */}
          {[0, 1, 2].includes(updatedTab) && (
            <button
              className="reject_btn"
              style={{ height: 36, margin: "0 8px" }}
              onClick={() => {
                setShowDeleteModal(true)
                setItemIdToDelete(item?._id)
              }}
            >
              {t("Cancel")}
            </button>
          )}

          {/* Assign Provider Button only in tab 1 */}
          {/* {updatedTab === 1 && (
            <button
              className="primary_btn"
              style={{ height: 36 }}
              onClick={() => {
                toggleModal() // Function to handle assign action
                fetchAvailableServiceProvider(item?._id)
                setSelectedId(item?._id) // Save selected item ID
              }}
            >
              {t("Assign Provider")}
            </button>
          )} */}
        </div>
      )
    }

    // Handle nested accessors
    const keys = accessor.split(".")
    const value = keys.reduce(
      (obj, key) => (obj && obj[key] !== undefined ? obj[key] : null),
      item
    )

    if (value === null || value === undefined) {
      return "-" // Fallback for missing values
    }

    // Special formatting cases
    if (accessor === "price") {
      return `${value} SAR` // Append currency
    }

    if (accessor === "status") {
      const statusMapping = serviceRequestStatus.find(
        status => status.value === value
      )
      return statusMapping ? t(statusMapping.label) : "-"
    }

    if (accessor === "requestType") {
      const requestType = serviceRequestType.find(type => type.value === value)
      return requestType ? t(requestType.label) : "-"
    }

    if (accessor === "scheduledDate") {
      return FormateOnlyDate(value) ?? "-"
    }
    if (accessor === "isPaymentDone") {
      return item?.requestType === 2 ? (
        "-"
      ) : (
        <p
          className={`${
            item.isPaymentDone === false ? "sold_status" : "rended_status"
          } status_tag`}
        >
          {item.isPaymentDone === false ? t("Pending") : t("Received")}
        </p>
      )
    }

    if (accessor === "startedAt" || accessor === "completedAt") {
      return FormateOnlyTime(value) ?? "-"
    }

    return value // Default rendering
  }

  const columns = getColumns()

  const onInputChange = value => {
    const payload = {
      keyword: value,
    }
    dispatch(getCustomersList(payload))
  }

  const handleAssignServiceProvider = (values, { resetForm }) => {
    const { serviceProviderId } = values

    const payload = {
      id: selectedId,
      serviceProviderId: serviceProviderId.value,
    }

    dispatch(assignServiceProvider(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          toggleModal()
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const handleCancelServiceRequest = () => {
    const payload = {
      id: itemIdToDelete,
    }

    dispatch(cancelServiceRequest(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <TopTitle
            title={t("Service Request")}
            subTitle={t(`Hi, {{name}}. Welcome back to your Admin portal!`, {
              name: name,
            })}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <Title title={t("Service Request List")} />

                <Nav tabs className="nav-tabs-custom nav-justified mb-4">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classNames({ active: activeTab === -1 })}
                      onClick={() => {
                        toggle(-1)
                      }}
                    >
                      <span>{t("All")}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classNames({ active: activeTab === 0 })}
                      onClick={() => {
                        toggle(0)
                      }}
                    >
                      <span>{t("Requested")}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classNames({ active: activeTab === 1 })}
                      onClick={() => {
                        toggle(1)
                      }}
                    >
                      <span>{t("Pending")}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classNames({ active: activeTab === 2 })}
                      onClick={() => {
                        toggle(2)
                      }}
                    >
                      <span>{t("Assigned")}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classNames({ active: activeTab === 3 })}
                      onClick={() => {
                        toggle(3)
                      }}
                    >
                      <span>{t("In Progress")}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classNames({ active: activeTab === 4 })}
                      onClick={() => {
                        toggle(4)
                      }}
                    >
                      <span>{t("Completed")}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classNames({ active: activeTab === 5 })}
                      onClick={() => {
                        toggle(5)
                      }}
                    >
                      <span>{t("Cancelled")}</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={filterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                  onInputChange={onInputChange}
                  colSize={4}
                />
                {loading ? (
                  <div style={{ marginTop: 150 }}>
                    <Spinners />
                  </div>
                ) : maintenanceServiceRequest.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="full_th">
                        <tr>
                          {columns.map(column => (
                            <th key={column.accessor}>{column.header}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {maintenanceServiceRequest.map((item, index) => (
                          <tr key={item._id}>
                            {columns.map(column => (
                              <td
                                key={column.accessor}
                                onClick={() =>
                                  navigate(
                                    `/service-request/details?id=${item._id}`
                                  )
                                }
                                className="cursor-pointer"
                              >
                                {renderCell(item, column.accessor, index)}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && maintenanceServiceRequest.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={modal}
        toggle={toggleModal}
        centered
        className={`g_modal w_600`}
      >
        <h2 className="modal_title">{t("Assign Service Provider")}</h2>
        <button type="button" onClick={toggleModal} className="modal_close_btn">
          <i className="mdi mdi-close"></i>
        </button>
        <div>
          <Row>
            <Col lg={12}>
              <Formik
                initialValues={{
                  serviceProviderId: "",
                }}
                validationSchema={assignServiceProviderSchema}
                onSubmit={handleAssignServiceProvider}
              >
                {({ values, errors, touched, setFieldValue }) => (
                  <Form>
                    <Row>
                      <Col lg={12}>
                        <div className="g_label">
                          <CustomLabel
                            htmlFor="serviceProviderId"
                            label={t("Select Service Provider")}
                            isRequired={true}
                          />
                          <Select
                            value={values.serviceProviderId}
                            onChange={item => {
                              setFieldValue("serviceProviderId", item)
                            }}
                            options={availableServiceProvider?.map(item => ({
                              label: item.name,
                              value: item._id,
                            }))}
                            className={`select2-selection b_r_12 ${
                              touched.serviceProviderId &&
                              errors.serviceProviderId
                                ? "danger_color_border"
                                : ""
                            } `}
                            placeholder={t("Select Service Provider")}
                          />
                          <CustomErrorMessage name={"serviceProviderId"} />
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="d-flex justify-content-end mt-5">
                          <button
                            type="button"
                            className="cancel_btn mx-2"
                            onClick={toggleModal}
                          >
                            {t("Cancel")}
                          </button>
                          <button
                            className="primary_btn"
                            type="submit"
                            disabled={isLoading}
                          >
                            {t("Assign")}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </div>
      </Modal>
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleCancelServiceRequest}
        onCloseClick={() => {
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        }}
        isLoading={isLoading}
        buttonText={t("Cancel")}
        bodyTitle={t("Are you sure you want to cancel this {{name}}?", {
          name: t("Service Request"),
        })}
      />
    </React.Fragment>
  )
}

export default ServiceRequest
