import { createSlice } from "@reduxjs/toolkit"
import {
  assignServiceProvider,
  cancelServiceRequest,
  createMaintenanceUser,
  deleteMaintenanceUser,
  getAllMaintenanceUsers,
  getAllRatingsFeedback,
  getAllServiceRequest,
  getAvailableServiceProvider,
  getMaintenanceUserDetails,
  getMaintenanceUsersList,
  getRatingsFeedbackDetails,
  getServiceRequestDetails,
  updateMaintenanceUser,
  updateMaintenanceUserStatus,
} from "store/actions/maintenanceUsersAction"

const initialState = {
  loading: true,
  isLoading: false,
  perPage: 10,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  maintenanceUsers: [],
  maintenanceUsersList: [],
  maintenanceUserDetails: {},
  maintenanceServiceRequest: [],
  availableServiceProvider: [],
  maintenanceServiceRequestDetails: {},
  ratingsAndFeedback: [],
  ratingsAndFeedbackDetails: {},
}
const maintenanceUsersSlice = createSlice({
  name: "maintenance users",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getAllMaintenanceUsers.pending, state => {
        state.loading = true
        state.totalCount = null
        state.totalPages = null
        state.currentPage = null
      })
      .addCase(getAllMaintenanceUsers.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.maintenanceUsers = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllMaintenanceUsers.rejected, (state, action) => {
        state.loading = false
      })

    // GET LIST
    builder
      .addCase(getMaintenanceUsersList.pending, state => {})
      .addCase(getMaintenanceUsersList.fulfilled, (state, action) => {
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.maintenanceUsersList = data
        }
      })
      .addCase(getMaintenanceUsersList.rejected, (state, action) => {})

    // GET DETAILS
    builder
      .addCase(getMaintenanceUserDetails.pending, state => {
        state.loading = true
        state.maintenanceUserDetails = {}
      })
      .addCase(getMaintenanceUserDetails.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.maintenanceUserDetails = data
        }
      })
      .addCase(getMaintenanceUserDetails.rejected, (state, action) => {
        state.loading = false
      })

    // UPDATE STATUS
    builder
      .addCase(updateMaintenanceUserStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(updateMaintenanceUserStatus.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id, status } = action.meta.arg
          state.maintenanceUsers = state.maintenanceUsers.map(item =>
            item._id === id ? { ...item, status: Number(status) } : item
          )
        }
      })
      .addCase(updateMaintenanceUserStatus.rejected, (state, action) => {
        state.isLoading = false
      })

    // DELETE
    builder
      .addCase(deleteMaintenanceUser.pending, state => {
        state.isLoading = true
      })
      .addCase(deleteMaintenanceUser.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.maintenanceUsers = state.maintenanceUsers.filter(
            item => item._id !== id
          ) //
          if (state.totalCount !== null) {
            state.totalCount -= 1
          }
        }
      })
      .addCase(deleteMaintenanceUser.rejected, (state, action) => {
        state.isLoading = false
      })

    // CREATE
    builder
      .addCase(createMaintenanceUser.pending, state => {
        state.isLoading = true
      })
      .addCase(createMaintenanceUser.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(createMaintenanceUser.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPDATE
    builder
      .addCase(updateMaintenanceUser.pending, state => {
        state.isLoading = true
      })
      .addCase(updateMaintenanceUser.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(updateMaintenanceUser.rejected, (state, action) => {
        state.isLoading = false
      })

    // GET ALL SERVICES
    builder
      .addCase(getAllServiceRequest.pending, state => {
        state.loading = true
        state.totalCount = null
        state.totalPages = null
        state.currentPage = null
      })
      .addCase(getAllServiceRequest.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.maintenanceServiceRequest = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllServiceRequest.rejected, (state, action) => {
        state.loading = false
      })

    // GET DETAILS
    builder
      .addCase(getServiceRequestDetails.pending, state => {
        state.loading = true
        state.maintenanceServiceRequestDetails = {}
      })
      .addCase(getServiceRequestDetails.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.maintenanceServiceRequestDetails = data
        }
      })
      .addCase(getServiceRequestDetails.rejected, (state, action) => {
        state.loading = false
      })

    // GET AVAILABLE SERVICE PROVIDER
    builder
      .addCase(getAvailableServiceProvider.pending, state => {})
      .addCase(getAvailableServiceProvider.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.availableServiceProvider = data
        }
      })
      .addCase(getAvailableServiceProvider.rejected, (state, action) => {})

    // ASSIGN SERVICE PROVIDER
    builder
      .addCase(assignServiceProvider.pending, state => {
        state.isLoading = true
      })
      .addCase(assignServiceProvider.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.maintenanceServiceRequest =
            state.maintenanceServiceRequest.filter(item => item._id !== id)
        }
      })
      .addCase(assignServiceProvider.rejected, (state, action) => {
        state.isLoading = false
      })

    // CANCEL SERVICE REQUEST
    builder
      .addCase(cancelServiceRequest.pending, state => {
        state.isLoading = true
      })
      .addCase(cancelServiceRequest.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.maintenanceServiceRequest =
            state.maintenanceServiceRequest.filter(item => item._id !== id)
        }
      })
      .addCase(cancelServiceRequest.rejected, (state, action) => {
        state.isLoading = false
      })

    // RATINGS AND FEEDBACK
    builder
      .addCase(getAllRatingsFeedback.pending, state => {
        state.loading = true
        state.totalCount = null
        state.totalPages = null
        state.currentPage = null
      })
      .addCase(getAllRatingsFeedback.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.ratingsAndFeedback = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllRatingsFeedback.rejected, (state, action) => {
        state.loading = false
      })

    // GET DETAILS
    builder
      .addCase(getRatingsFeedbackDetails.pending, state => {
        state.loading = true
        state.ratingsAndFeedbackDetails = {}
      })
      .addCase(getRatingsFeedbackDetails.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.ratingsAndFeedbackDetails = data
        }
      })
      .addCase(getRatingsFeedbackDetails.rejected, (state, action) => {
        state.loading = false
      })
  },
})

export default maintenanceUsersSlice.reducer
