import React, { useEffect, useRef, useState } from "react"
import { Card, Col, Container, Modal, Row, Table } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import useFilters from "components/Shared/useFilters"
import Title from "components/Shared/Title"
import Filters from "components/Shared/Filters"
import {
  ascDescOptions,
  customerSupportChannelOptions,
  customerSupportPriorityOptions,
  customerSupportStatusOptions,
  customerSupportSubjectOptions,
} from "components/Common/constants"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import { complaintsStatusUpdateSchema } from "components/Common/validation"
import { Field, Form, Formik } from "formik"
import CustomLabel from "components/Shared/CustomLabel"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import Paginations from "components/Common/Pagination"
import { useDispatch, useSelector } from "react-redux"
import {
  getAllCustomerSupport,
  getCustomerSupportSubjectList,
  updateCustomerSupportStatus,
} from "store/actions/customerSupportAction"
import {
  DownloadCSV,
  DownloadXLSX,
  getDateRange,
} from "components/Common/Utils"
import Spinners from "components/Shared/Spinners"
import ViewIcon from "assets/images/icons/view.svg"
import EditIcon from "assets/images/icons/edit.svg"
import { showAlert } from "store/reducers/alertReducer"
import { exportData } from "helpers/api_helper"
import { EXPORT_DATA } from "helpers/url_helper"

const CustomerSupport = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Customer Support | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const { userType } = useSelector(state => state.Login)

  const {
    loading,
    isLoading,
    customerSupport,
    totalCount,
    totalPages,
    currentPage,
    perPage,
    customerSupportSubjectList,
  } = useSelector(state => state.CustomerSupport)

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    status: -1,
    orderBy: 2,
    subject: [],
    // priority: [],
  })

  const [selectedStatus, setSelectedStatus] = useState(null)
  const [isListLoaded, setIsListLoaded] = useState(false)

  useEffect(() => {
    const fetchAllCustomerSupportList = () => {
      const role =
        userType === "superadmin"
          ? []
          : [JSON.parse(localStorage.getItem("authUser"))?.roleId]

      const payload = {
        role,
      }
      dispatch(getCustomerSupportSubjectList(payload))
        .then(action => {
          const { statusCode, success, message } = action?.payload
          if (statusCode === 200 && success) {
            setIsListLoaded(true)
          } else {
            setIsListLoaded(true)
          }
        })
        .catch(error => {})
    }

    fetchAllCustomerSupportList()
  }, [])

  useEffect(() => {
    fetchAllCustomerSupport()
  }, [])

  const fetchAllCustomerSupport = page => {
    const { status, orderBy, subject, priority, channel } = filters

    const updatedSubject = subject?.map(item => item.value)

    // const updatedPriority = priority?.map(item => item.value) || []
    // const updatedChannel = channel?.map(item => item.value) || []

    const payload = {
      perPage: perPage,
      pageNo: page || pageNumber,
      status: status,
      orderBy,
      keyword,
      customer: [], //array of ids
      // priority: updatedPriority, //high, low, medium, urgent
      // channel: updatedChannel, //App, WhatsApp
      subject: updatedSubject,
      dateRange: getDateRange(dateRange),
    }
    dispatch(getAllCustomerSupport(payload))
    setIsApiCalled(true)

    navigate(`/customer-support?page=${page || pageNumber}`)
  }

  const additionOptions = [
    {
      name: "status",
      type: "select",
      options: customerSupportStatusOptions?.map(item => ({
        label: t(item.label),
        value: item.value,
      })),
      placeholder: t("All"),
    },
    // ...(userType === "customerService"
    //   ? [
    //       {
    //         name: "subject",
    //         type: "multiSelect",
    //         options: customerSupportSubjectOptions?.map(item => ({
    //           label: t(item.label),
    //           value: item.value,
    //         })),
    //         placeholder: t("Subject"),
    //       },
    //     ]
    //   : []),
    // {
    //   name: "channel",
    //   type: "multiSelect",
    //   options: customerSupportChannelOptions?.map(item => ({
    //     label: t(item.label),
    //     value: item.value,
    //   })),
    //   placeholder: t("Channel"),
    // },
    // {
    //   name: "priority",
    //   type: "multiSelect",
    //   options: customerSupportPriorityOptions?.map(item => ({
    //     label: t(item.label),
    //     value: item.value,
    //   })),
    //   placeholder: t("Priority"),
    // },
    {
      name: "subject",
      type: "multiSelect",
      options: customerSupportSubjectList?.map(item => ({
        label: language === "sa" ? item.titleAr : item.title,
        value: item._id,
      })),
      placeholder: t("Subject"),
    },
  ]

  const filterOptions = [...ascDescOptions, ...additionOptions]

  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchAllCustomerSupport(1)
  }

  const onPageChange = page => {
    fetchAllCustomerSupport(page)
  }

  const [modal, setModal] = useState(false)

  const toggle = () => {
    modal && setSelectedId(null)
    modal && setSelectedStatus(null)
    setModal(!modal)
  }

  const handleStatusChange = (values, { resetForm }) => {
    const { status, description } = values
    const payload = {
      id: selectedId,
      status,
      description,
    }
    dispatch(updateCustomerSupportStatus(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          toggle()
          resetForm()
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const getFilteredOptions = currentStatus => {
    if (currentStatus === 3) {
      return [
        customerSupportStatusOptions?.find(option => option.value === 4), // Re-Opened
        customerSupportStatusOptions?.find(option => option.value === 5), // Closed
        customerSupportStatusOptions?.find(option => option.value === 6), // Hold
      ].filter(Boolean) // Removes any undefined values
    }
    if (currentStatus === 4) {
      return [
        customerSupportStatusOptions?.find(option => option.value === 2), // Closed
        customerSupportStatusOptions?.find(option => option.value === 6), // Hold
      ].filter(Boolean) // Removes any undefined values
    }

    if (currentStatus === 6) {
      return [
        customerSupportStatusOptions.find(option => option.value === 2), // In Progress
      ].filter(Boolean)
    }

    const currentIndex = customerSupportStatusOptions.findIndex(
      option => option.value === currentStatus
    )

    const nextStatus = customerSupportStatusOptions[currentIndex + 1]

    if (currentStatus === 5) {
      // If current status is Closed, return an empty array (no next statuses)
      return []
    }

    if (nextStatus) {
      if (nextStatus.value === 5) {
        // If next status is Closed, include Closed and Cancelled
        return [nextStatus, customerSupportStatusOptions[5]]
      } else {
        // Otherwise, include the next status and Cancelled
        return [nextStatus, customerSupportStatusOptions[5]].filter(
          (item, index, self) =>
            self.findIndex(i => i.value === item.value) === index
        )
      }
    }

    // If no next status, return an empty array
    return []
  }

  const getCurrentStatusLabel = currentStatus => {
    const currentStatusOption = customerSupportStatusOptions.find(
      option => option.value === currentStatus
    )
    return currentStatusOption ? currentStatusOption.label : t("Update Status")
  }

  const getExportFilters = () => {
    const { status, subject, priority, channel } = filters

    const updatedSubject =
      userType === "customerService"
        ? subject?.map(item => item.value) || []
        : userType === "finance"
        ? ["Finance"]
        : userType === "sales"
        ? ["Sales"]
        : userType === "marketing"
        ? ["Marketing"]
        : []

    // const updatedPriority = priority?.map(item => item.value) || []
    // const updatedChannel = channel?.map(item => item.value) || []

    return {
      customer: [],
      // priority: updatedPriority,
      // channel: updatedChannel,
      subject: updatedSubject,
      status: status,
      dateRange: getDateRange(dateRange),
    }
  }

  const handleDownloadCSV = async () => {
    const payload = {
      type: "SupportTicket",
      exportType: "csv",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadCSV(csvData, t("Customer Support"))
    }
  }

  const handleDownloadXLSX = async () => {
    const payload = {
      type: "SupportTicket",
      exportType: "xlsx",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadXLSX(csvData, t("Customer Support"))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <Title
                  title={t("Customer Support")}
                  isExport={true}
                  handleDownloadCSV={handleDownloadCSV}
                  handleDownloadXLSX={handleDownloadXLSX}
                />
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={filterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                  colSize={4}
                />
                {loading ? (
                  <div className="mt-5">
                    <Spinners />
                  </div>
                ) : customerSupport.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="full_th">
                        <tr>
                          <th>{t("#")}</th>
                          <th>{t("Ticket Number")}</th>
                          <th>{t("Customer Name")}</th>
                          <th>{t("Customer Phone")}</th>
                          <th>{t("Customer Email")}</th>
                          <th>{t("Subject")}</th>
                          {/* <th>{t("Channel")}</th> */}
                          {/* <th>{t("Priority")}</th> */}
                          <th>{t("Status")}</th>
                          <th>{t("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customerSupport.map((support, index) => (
                          <tr
                            key={support._id}
                            className="cursor-pointer"
                            onClick={() => {
                              navigate(
                                `/customer-support/details?id=${support._id}`
                              )
                            }}
                          >
                            <th scope="row">
                              {(currentPage - 1) * perPage + index + 1}
                            </th>
                            <td>{support.ticketNumber || "-"}</td>
                            <td>{support.name || "-"}</td>
                            <td>{support.phone || "-"}</td>
                            <td>{support.email || "-"}</td>
                            <td style={{ minWidth: 250 }}>
                              {language === "sa"
                                ? support?.subject?.titleAr
                                : support?.subject?.title || "-"}
                            </td>
                            {/* <td>{t(support.channel || "-")}</td> */}
                            {/* <td className="text-capitalize">
                              {t(support.priority || "-")}
                            </td> */}
                            <td>
                              <p
                                className={`${
                                  customerSupportStatusOptions.find(
                                    item => item.value === support.status
                                  )?.className || "-"
                                }  status_tag fit_content`}
                              >
                                {t(
                                  customerSupportStatusOptions.find(
                                    item => item.value === support.status
                                  )?.label || "-"
                                )}
                              </p>
                            </td>
                            <td onClick={e => e.stopPropagation()}>
                              <div className="d-flex align-items-center gap-2">
                                {support.status === 5 ? null : (
                                  <img
                                    src={EditIcon}
                                    alt="Edit Icon"
                                    width={24}
                                    height={24}
                                    className="cursor-pointer"
                                    onClick={() => {
                                      toggle()
                                      setSelectedId(support._id)
                                      setSelectedStatus(support.status)
                                    }}
                                  />
                                )}
                                <img
                                  src={ViewIcon}
                                  alt="View Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer me-3"
                                  onClick={() => {
                                    navigate(
                                      `/customer-support/details?id=${support._id}`
                                    )
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && customerSupport.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        className={`g_modal w_600`}
      >
        <h2 className="modal_title">{t("Update Ticket Status")}</h2>
        <button type="button" onClick={toggle} className="modal_close_btn">
          <i className="mdi mdi-close"></i>
        </button>
        <div>
          <Row>
            <Col lg={12}>
              <Formik
                initialValues={{ status: "", description: "" }}
                validationSchema={complaintsStatusUpdateSchema}
                onSubmit={handleStatusChange}
              >
                {({ values, errors, touched, setFieldValue }) => (
                  <Form>
                    <Row>
                      <Col lg={12}>
                        <div className="form_field">
                          <CustomLabel
                            htmlFor="status"
                            label={t("Update Status")}
                            isRequired={true}
                          />
                          <div className="select_wrapper">
                            <Field
                              as="select"
                              name="status"
                              className={`form-control b_r_12 ${
                                errors.status && touched.status
                                  ? "is-invalid"
                                  : ""
                              }`}
                              style={
                                selectedStatus === 5
                                  ? { cursor: "not-allowed" }
                                  : {}
                              }
                              onChange={e => {
                                const selectedValue = e.target.value
                                setFieldValue("status", selectedValue)
                              }}
                              disabled={selectedStatus === 5}
                            >
                              <option hidden>
                                {t(getCurrentStatusLabel(selectedStatus))}
                              </option>
                              {getFilteredOptions(selectedStatus).map(item => (
                                <option key={item.value} value={item.value}>
                                  {t(item.label)}
                                </option>
                              ))}
                            </Field>
                          </div>
                          <CustomErrorMessage name={"status"} />
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="form_field">
                          <CustomLabel
                            htmlFor="description"
                            label={t("Comment")}
                          />
                          <Field
                            as="textarea"
                            id="description"
                            name="description"
                            rows={4}
                            placeholder={t("Write  here...")}
                            className={`form-control b_r_12 ${
                              errors.description && touched.description
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                        </div>
                      </Col>
                      <Col lg={12}>
                        <button
                          className="save_btn mt-4 ms-auto d-block"
                          type="submit"
                          disabled={isLoading || selectedStatus === 5}
                        >
                          {t("Update")}
                        </button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default CustomerSupport
